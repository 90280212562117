<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="12">
      <LocationsCard
        v-for="(address, idx) in client.client_address"
        :key="idx"
        :address="address"
        :address_idx="idx"
        @setAddress="address = $event"
        @remove="removeAddress"
      />
    </v-col>
    <v-col cols="12" md="12">
      <v-btn @click="addAddress" class="primary" icon dark>
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
  },
  components: {
    LocationsCard: () => import("./LocationsCard"),
  },
  methods: {
    addAddress() {
      this.client.client_address.push({})
    },
    removeAddress(address) {
      if(this.client.client_address.length > 1){
        let idx = this.client.client_address.indexOf(address);
        this.client.client_address.splice(idx, 1);
      }
    },
  }
}
</script>